@import "tailwindcss/base";



@import "./typography.css";

@import "./layout.css";


@import "tailwindcss/components";



@import "tailwindcss/utilities";