.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    border-radius: 0px;
}

.hamburger:hover {
    @apply opacity-100;
}

.hamburger.is-active:hover {
    @apply opacity-100;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    @apply bg-white;
}


.move-left {
    transform: translateX(-33.333333vw);
}

.moveme {
    @apply transition-transform duration-500;
}

@media screen(md) {
    .special-height {
        height: calc(100vh - 7rem);
    }
}

.music-embed {
    @apply flex-1;
}


.bounces {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

/* Scroll down indicator (bouncing) */
@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px); }
  }



@-moz-keyframes bounce {
  0%, 100% {
    -moz-transform: translateY(0); }
  50% {
    -moz-transform: translateY(-10px); }

  }